import {
    Box,
    Button,
    Card,
    Center,
    HStack,
    IconButton,
    ListIcon,
    ListItem,
    Text,
    Tooltip,
    useDisclosure,
    useTheme,
} from '@chakra-ui/react';
import { useGetZonesPagination } from '../../query/resource-hooks/company';
import InfiniteList from '../../components/lists/InfiniteList';
import { Zone } from '../../types';
import { FiMap, FiRefreshCcw } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import SearchInput from '../../components/ui/SearchInput';
import { useState } from 'react';
import { PlusSquareIcon } from '@chakra-ui/icons';
import { useIntl } from 'react-intl';
import { ZoneForm } from '../../components/zones/ZoneForm';
import UserPolicy from '../../policies/UserPolicy';
import { useAuthStore } from '../../store/auth/authStore';
import { FaArchive } from 'react-icons/fa';
import ZonesListSkeleton from './ZonesListSkeleton';

interface ZoneListItemProps {
    zone: { name: string };
    onClick?: () => void;
}

const ZoneListItem: React.FC<ZoneListItemProps> = ({ zone, onClick }) => {
    const theme = useTheme();

    return (
        <ListItem
            onClick={onClick}
            _hover={{
                bg: theme.colors.primary[500],
                color: 'white',
            }}
            cursor={'pointer'}
            borderRadius={'lg'}
        >
            <HStack p={1}>
                <ListIcon as={FiMap} color="green.500" />
                <Text fontWeight={600} fontSize={'md'}>
                    {zone.name}
                </Text>
            </HStack>
        </ListItem>
    );
};

const ZonesList: React.FC = () => {
    const navigate = useNavigate();
    const intl = useIntl();
    const [search, setSearch] = useState('');
    const zonesQuery = useGetZonesPagination({ search: search });
    const loggedUser = useAuthStore((s) => s.user);
    const addZoneDisclosure = useDisclosure();
    return (
        <Box>
            <Center>
                <Card my={2} mx={{ base: 2, md: 0 }} width={'100%'} maxWidth={'lg'} borderRadius={'full'} boxShadow={'md'}>
                    <Box>
                        <SearchInput onSearch={setSearch} />
                    </Box>
                </Card>
            </Center>

            <HStack justifyContent={'space-between'} mx={{ base: 2, md: 4 }} align={'center'}>
                <Tooltip label={intl.formatMessage({ id: 'createZone' })}>
                    <Button
                        ml={2}
                        onClick={addZoneDisclosure.onOpen}
                        borderRadius={'full'}
                        leftIcon={<PlusSquareIcon />}
                        isDisabled={!UserPolicy.canCreateEmployee(loggedUser!)}
                        boxShadow={'md'}
                    >
                        {intl.formatMessage({ id: 'tab.Add' })}
                    </Button>
                </Tooltip>
                <Box>
                    <Tooltip label={intl.formatMessage({ id: 'archive.zones.title' })}>
                        <Button ml={2} onClick={() => navigate('archive')} leftIcon={<FaArchive />} variant={'ghost'}>
                            {intl.formatMessage({ id: 'doc.archive.title' })}
                        </Button>
                    </Tooltip>
                    <Tooltip label={intl.formatMessage({ id: 'refresh' })}>
                        <IconButton
                            icon={<FiRefreshCcw />}
                            aria-label="refresh"
                            m={2}
                            variant={'text'}
                            onClick={() => zonesQuery.refetch()}
                        />
                    </Tooltip>
                </Box>
            </HStack>
            <Card mx={{ base: 0, md: 4 }} p={4} borderRadius={'lg'}>
                <InfiniteList<Zone>
                    data={zonesQuery.data}
                    fetchNextPage={zonesQuery.fetchNextPage}
                    hasNextPage={zonesQuery.hasNextPage}
                    isFetchingNextPage={zonesQuery.isFetchingNextPage}
                    renderItem={(zone, index) => <ZoneListItem onClick={() => navigate(`detail/${zone.id}`)} zone={zone} key={index} />}
                    isLoading={zonesQuery.isLoading || zonesQuery.isRefetching}
                    loadingComponent={<ZonesListSkeleton />}
                />
            </Card>
            <ZoneForm
                isOpen={addZoneDisclosure.isOpen}
                onClose={addZoneDisclosure.onClose}
                onSuccess={(zone) => {
                    navigate(`detail/${zone.id}`);
                    addZoneDisclosure.onClose();
                }}
            />
        </Box>
    );
};

export default ZonesList;
