import {
    Avatar,
    Box,
    useTheme,
    ListItem,
    VStack,
    Text,
    HStack,
    Divider,
    Card,
    Center,
    useDisclosure,
    Button,
    IconButton,
    CardBody,
    Tooltip,
} from '@chakra-ui/react';
import { User } from '../../types';
import { useIntl } from 'react-intl';
import { useGetEmployeesPagination } from '../../query/resource-hooks/company';
import InfiniteList from '../../components/lists/InfiniteList';
import { useNavigate } from 'react-router-dom';
import SearchInput from '../../components/ui/SearchInput';
import { useState } from 'react';
import { PlusSquareIcon } from '@chakra-ui/icons';
import { EmployeeForm } from '../../components/employee/EmployeeForm';
import { FiRefreshCcw } from 'react-icons/fi';
import { useAuthStore } from '../../store/auth/authStore';
import UserPolicy from '../../policies/UserPolicy';
import { FaArchive } from 'react-icons/fa';
import EmployeesListSkeleton from './EmployeesListSkeleton';

const EmployeeListItem: React.FC<{ user: User; onClick?: () => void }> = ({ user, onClick }) => {
    const intl = useIntl();
    const theme = useTheme();
    return (
        <>
            <ListItem
                _hover={{
                    bg: theme.colors.primary[500],
                    color: 'white',
                }}
                padding={1}
                role="group"
                cursor="pointer"
                borderRadius="lg"
                onClick={onClick}
            >
                <HStack>
                    <Avatar name={user.surname || user.firstName} />
                    <VStack align={'start'} spacing={0}>
                        <Text fontWeight={'600'}>{`${user?.surname || ''} ${user.firstName}`}</Text>
                        <Text fontSize={'small'}>{user.role ? intl.formatMessage({ id: user.role }) : '-'}</Text>
                    </VStack>
                </HStack>
            </ListItem>
            <Divider />
        </>
    );
};

const EmployeesList: React.FC = () => {
    const navigate = useNavigate();
    const intl = useIntl();
    const loggedUser = useAuthStore((s) => s.user);
    const [search, setSearch] = useState('');
    const newEmployeeDisclosure = useDisclosure();
    const employeesQuery = useGetEmployeesPagination({ search: search });

    return (
        <>
            <Box textAlign="center">
                <Center>
                    <Card my={2} mx={{ base: 2, md: 0 }} width={'100%'} maxWidth={'lg'} borderRadius={'full'} boxShadow={'md'}>
                        <Box>
                            <SearchInput onSearch={setSearch} />
                        </Box>
                    </Card>
                </Center>

                <HStack justifyContent={'space-between'} mx={{ base: 2, md: 4 }} align={'center'}>
                    <Tooltip label={intl.formatMessage({ id: 'createUser' })}>
                        <Button
                            ml={2}
                            onClick={newEmployeeDisclosure.onOpen}
                            borderRadius={'full'}
                            leftIcon={<PlusSquareIcon />}
                            isDisabled={!UserPolicy.canCreateEmployee(loggedUser!)}
                            boxShadow={'md'}
                        >
                            {intl.formatMessage({ id: 'tab.Add' })}
                        </Button>
                    </Tooltip>
                    <Box>
                        <Tooltip label={intl.formatMessage({ id: 'archive.employees.title' })}>
                            <Button ml={2} onClick={() => navigate('archive')} variant={'ghost'} leftIcon={<FaArchive />}>
                                {intl.formatMessage({ id: 'doc.archive.title' })}
                            </Button>
                        </Tooltip>
                        <Tooltip label={intl.formatMessage({ id: 'refresh' })}>
                            <IconButton
                                icon={<FiRefreshCcw />}
                                aria-label="refresh"
                                m={2}
                                variant={'text'}
                                onClick={() => employeesQuery.refetch()}
                            />
                        </Tooltip>
                    </Box>
                </HStack>
                <Card mx={{ base: 2, md: 4 }} borderRadius={'lg'}>
                    <CardBody>
                        <InfiniteList<User>
                            data={employeesQuery.data}
                            fetchNextPage={employeesQuery.fetchNextPage}
                            hasNextPage={employeesQuery.hasNextPage}
                            isFetchingNextPage={employeesQuery.isFetchingNextPage}
                            isLoading={employeesQuery.isLoading || employeesQuery.isRefetching}
                            renderItem={(user, index) => (
                                <EmployeeListItem
                                    onClick={() => {
                                        navigate(`detail/${user.id}`);
                                    }}
                                    key={index}
                                    user={user}
                                />
                            )}
                            loadingComponent={<EmployeesListSkeleton />}
                        />
                    </CardBody>
                </Card>
            </Box>

            <EmployeeForm isOpen={newEmployeeDisclosure.isOpen} onClose={newEmployeeDisclosure.onClose} />
        </>
    );
};

export default EmployeesList;
