import { TableContainer, Table, Tr, Tbody, Tfoot, Flex, Button, Td, Center, Text, useDisclosure } from '@chakra-ui/react';
import { InfiniteData } from 'react-query';
import { AttendanceLogin, LoginPagination } from '../../types';
import React from 'react';
import { useIntl } from 'react-intl';
import './AttendanceTable.css';
import { AttendanceDetailModal } from '../../components/attendance/AttendanceDetailModal';
import { AttendanceTableSkeleton } from './AttendanceTableSkeleton';
import { useAuthStore } from '../../store/auth/authStore';
import { useNavigate, useLocation } from 'react-router-dom';

interface AttendanceTableProps {
    data?: InfiniteData<LoginPagination>;
    fetchNextPage: () => void;
    hasNextPage: boolean | undefined;
    isFetchingNextPage: boolean;
    renderItem: (item: AttendanceLogin, showPreview: () => void) => JSX.Element;
    isLoading?: boolean;
    handleNavigateToDetail?: (id: number) => void;
}

type AttendanceTableItemClickPayload = {
    currentId: number;
    hasNext: boolean;
    hasPrevious: boolean;
    dataIndex: number;
    hasNextPage: boolean;
};

const AttendanceTable: React.FC<AttendanceTableProps> = ({
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    renderItem,
    isLoading,
    handleNavigateToDetail,
}) => {
    const items = data?.pages.flatMap((page) => page.data);
    const intl = useIntl();
    const previewDisclosure = useDisclosure();
    const [previewData, setPreviewData] = React.useState<AttendanceTableItemClickPayload>();
    const navigate = useNavigate();
    const location = useLocation();
    const loggedUser = useAuthStore((s) => s.user);

    React.useEffect(() => {
        const params = new URLSearchParams(location.search);
        const currentId = params.get('currentId');
        const dataIndex = params.get('dataIndex');
        const hasNext = params.get('hasNext') === 'true';
        const hasPrevious = params.get('hasPrevious') === 'true';
        const hasNextPage = params.get('hasNextPage') === 'true';

        if (currentId && dataIndex) {
            setPreviewData({
                currentId: Number(currentId),
                dataIndex: Number(dataIndex),
                hasNext,
                hasPrevious,
                hasNextPage,
            });
            previewDisclosure.onOpen();
        }
    }, [location.search]);

    function handleShowPreview(data: AttendanceTableItemClickPayload) {
        const params = new URLSearchParams(location.search);
        params.set('currentId', data.currentId.toString());
        params.set('dataIndex', data.dataIndex.toString());
        params.set('hasNext', data.hasNext.toString());
        params.set('hasPrevious', data.hasPrevious.toString());
        params.set('hasNextPage', data.hasNextPage.toString());
        navigate({ search: params.toString() }, { replace: true });
    }

    async function handleModalGoToNext(): Promise<void> {
        if (!items || previewData?.dataIndex === undefined) {
            console.log('NO DATA');
            return;
        }

        let nextItem = items[previewData?.dataIndex + 1];
        if (nextItem) {
            if (hasNextPage && items[previewData?.dataIndex + 2] === undefined) {
                fetchNextPage(); // Prefetch if we are at the end of the page
            }

            const params = new URLSearchParams(location.search);
            params.set('currentId', nextItem.id.toString());
            params.set('dataIndex', (previewData?.dataIndex + 1).toString());
            params.set('hasNext', (!!items[previewData?.dataIndex + 2]?.id).toString());
            params.set('hasPrevious', (!!items[previewData?.dataIndex]?.id).toString());
            params.set('hasNextPage', (hasNextPage ?? false).toString());
            navigate({ search: params.toString() }, { replace: true });
        }

        // This should never happen - we prefetch next data if current item does not have next
        // If this happens, user has to click next again
        if (!nextItem && hasNextPage) {
            fetchNextPage();
            nextItem = items[previewData?.dataIndex + 1];
        }
    }

    function handleModalGoToPrevious() {
        if (!items || previewData?.dataIndex === undefined) {
            return;
        }

        let previousItem = items[previewData?.dataIndex - 1];
        if (previousItem) {
            const params = new URLSearchParams(location.search);
            params.set('currentId', previousItem.id.toString());
            params.set('dataIndex', (previewData?.dataIndex - 1).toString());
            params.set('hasNext', (!!items[previewData?.dataIndex]?.id).toString());
            params.set('hasPrevious', (!!items[previewData?.dataIndex - 2]?.id).toString());
            params.set('hasNextPage', (hasNextPage ?? false).toString());
            navigate({ search: params.toString() }, { replace: true });
        }
    }

    function handleClosePreview() {
        previewDisclosure.onClose();
        const params = new URLSearchParams(location.search);
        params.delete('currentId');
        params.delete('dataIndex');
        params.delete('hasNext');
        params.delete('hasPrevious');
        params.delete('hasNextPage');
        navigate({ search: params.toString() }, { replace: true });
    }

    if (isLoading && !isFetchingNextPage) {
        return <AttendanceTableSkeleton />;
    }

    return (
        <>
            <TableContainer>
                <Table className="attendance_table" variant={'unstyled'} size={'sm'} overflow={'hidden'}>
                    <Tbody>
                        {!items ? (
                            <Tr>
                                <Td colSpan={3}>
                                    <AttendanceTableSkeleton />
                                </Td>
                            </Tr>
                        ) : items.length === 0 ? (
                            <Tr>
                                <Td colSpan={3}>
                                    <Center>
                                        <Text fontWeight={600}>{intl.formatMessage({ id: 'noData' })}</Text>
                                    </Center>
                                </Td>
                            </Tr>
                        ) : (
                            items.map((item, index) => {
                                return renderItem(item, () => {

                                    handleShowPreview({
                                        currentId: item.id,
                                        hasNext: !!items[index + 1]?.id,
                                        hasPrevious: !!items[index - 1]?.id,
                                        hasNextPage: hasNextPage ?? false,
                                        dataIndex: index,
                                    })
                                    
                                    if (hasNextPage && items[index + 1] === undefined) {
                                        fetchNextPage();  // Prefetch if we are at the end of the page
                                    }
                                }
                                );
                            })
                        )}
                    </Tbody>
                    <Tfoot>
                        <Tr>
                            <Td colSpan={3}>
                                {hasNextPage && (
                                    <Flex p={2} width={'full'} alignItems={'center'} justifyContent={'center'}>
                                        <Button colorScheme="primary" onClick={fetchNextPage} isLoading={isFetchingNextPage}>
                                            {intl.formatMessage({ id: 'viewMore' })}
                                        </Button>
                                    </Flex>
                                )}
                            </Td>
                        </Tr>
                    </Tfoot>
                </Table>
            </TableContainer>
            <AttendanceDetailModal
                isOpen={previewDisclosure.isOpen}
                onClose={handleClosePreview}
                currentId={previewData?.currentId}
                hasNext={previewData?.hasNext || previewData?.hasNextPage}
                handleGoToNext={() => handleModalGoToNext()}
                handleGoToPrevious={() => handleModalGoToPrevious()}
                handleGoToDetail={(id) => handleNavigateToDetail?.(id)}
                hasPrevious={previewData?.hasPrevious}
                loggedUser={loggedUser ?? undefined}
            />
        </>
    );
};

export default AttendanceTable;
